import { gsap, Power1 } from "gsap/gsap-core";

export default class NavMobile {

  constructor() {
    this.html = document.querySelector('html');
    this.body = document.querySelector('body');
    this.nav = document.querySelector('.navigation--header');

    if (this.nav.dataset.navMobileInitialized) {
      return;
    }
    this.nav.dataset.navMobileInitialized = true;

    this.header = document.querySelector('.header');
    this.navItems = document.querySelectorAll('.navigation--header li, .navigation--header .button-wrapper');
    this.triggerButton = document.querySelector('.navigation--trigger');
    this.triggers = document.querySelectorAll('.navigation--trigger-overlay');
    this.active = false;


    if (this.nav && this.triggers) {
      this.body.addEventListener('click', (event) => {
        // hide navigation
        if (this.active) {
          this.active = false;
          setTimeout(() => {
            this.html.classList.remove('overflow-hidden');
            this.header.classList.remove('navigation__active');
            this.nav.classList.replace('opacity-100', 'opacity-0');
            this.nav.classList.replace('visible', 'invisible');
            // this.nav.classList.replace('translate-x-0', '-translate-x-full');
            // this.triggerButton.classList.replace('text-white', 'text-theme-navigation');
          }, 500);


          // gsap.to(this.navItems, {
          //   x: '100%',
          //   duration: 0.7,
          //   repeat: false,
          //   stagger: -0.1,
          //   ease: Power1.easeInOut,
          //   onComplete: () => {
          //     gsap.to(this.navItems, {x: '-100%', duration: 0});
          //   }
          // });

        }
        // show navigation
        else {
          let triggerHit = false
          this.triggers.forEach((trigger, key) => {
            if (event.target == trigger) {
              triggerHit = true;
            }
          });

          if (triggerHit) {
            this.active = true;
            this.html.classList.add('overflow-hidden');
            this.header.classList.add('navigation__active');
            this.nav.classList.replace('opacity-0', 'opacity-100');
            this.nav.classList.replace('invisible', 'visible');
            // this.nav.classList.replace('-translate-x-full', 'translate-x-0');
            // this.triggerButton.classList.replace('text-theme-navigation', 'text-white');

            gsap.fromTo(this.navItems, {x: '-100%'}, {
              x: 0,
              duration: 0.7,
              repeat: false,
              stagger: 0.1,
              ease: Power1.easeInOut,
            });
          }
        }

        this.triggerButton.setAttribute('aria-pressed', this.active);
        this.triggerButton.setAttribute('aria-label', this.active ? 'Navigation ausblenden' : 'Navigation einblenden');
        this.nav.setAttribute('aria-hidden', !this.active);
      });

    }
  }

}
