export default class Video {

  constructor(selector) {
    this.wrappers = document.querySelectorAll(selector);
    if (!this.wrappers.length) {
      return;
    }

    for (let index = 0; index < this.wrappers.length; index++) {
      const wrapper = this.wrappers[index];
      const video = wrapper.querySelector('.video--video');
      const loop = wrapper.querySelector('.video--loop');
      const playTrigger = wrapper.querySelector('.video--play-trigger');
      const playTriggerLabel = wrapper.querySelector('.video--play-trigger-label');

      if (playTrigger) {
        playTrigger.addEventListener('click', (event) => {
          event.preventDefault();
          if (video.paused) {
            video.play();
          }
          else {
            video.pause();
          }
          loop.pause();
        });
      }


      if (wrapper) {
        wrapper.addEventListener('play', (event) => {
          if (event.target == video) {
            setTimeout(() => {
              wrapper.classList.add('is-playing');
              if (playTriggerLabel) {
                playTriggerLabel.textContent = 'Pause';
              }
            }, 96);
          }
        }, true);

        wrapper.addEventListener('pause', (event) => {
          if (event.target == video) {
            setTimeout(() => {
              wrapper.classList.remove('is-playing');
              if (playTriggerLabel) {
                playTriggerLabel.textContent = 'Play';
              }
            }, 96);
          }
        }, true);

        wrapper.addEventListener('ended', (event) => {
          if (event.target == video) {
            video.currentTime = 0;
            video.load();
            loop.play();

            setTimeout(() => {
              wrapper.classList.remove('is-playing');
              if (playTriggerLabel) {
                playTriggerLabel.textContent = 'Play';
              }
            }, 96);
          }
        }, true);
      }

      if (loop) {
        loop.play();
      }

    }
  }


}
