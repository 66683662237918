import once from "@drupal/once";
import Swiper from 'swiper';
import { EffectFade, Autoplay, Navigation } from "swiper/modules"

import "swiper/css"
import "swiper/css/autoplay"
import "swiper/css/effect-fade"

export default class Carousel  {

  constructor(selector) {
    const elements = once('carousel', selector);
    elements.forEach(element => {
      const swiper = new Swiper(element.querySelector('.swiper-carousel'), {
        modules: [EffectFade, Autoplay, Navigation],
        autoplay:{
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        effect: "fade",
        speed: 2000,
        loop : true,
        keyboard : {
          enabled: true,
          onlyInViewport: true,
        }
      });
    });


  }

}
