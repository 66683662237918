export default class Header {

  constructor() {
    this.html = document.querySelector('html');
    this.header = document.querySelector('.header');
    // this.footer = document.querySelector('.footer');
    this.headline = document.querySelector('.headline');

    window.addEventListener('resize', (event) => {
      this.resizeHandler();
    });
    this.resizeHandler();
  }

  resizeHandler() {
    this.html.style.setProperty('--header-height', this.header.getBoundingClientRect().height + 'px');
    // this.html.style.setProperty('--footer-height', this.footer.clientHeight + 'px');
    if (this.headline) {
      this.html.style.setProperty('--headline-height', this.headline.clientHeight + 'px');
    }
    else {
      this.html.style.setProperty('--headline-height', '0px');
    }
  }


}
