import barba from '@barba/core';
import { gsap, ScrollTrigger } from "gsap/all";
import Lenis from 'lenis'

import Header from "./header";
import NavMobile from "./nav-mobile";
import Reveal from "./reveal";
import Video from "./video";
import IdAccordion from './accordion';
import Carousel from './carousel';
import Text from './text';
import Hero from './hero';

export default class BarbaJs {

  constructor() {
    window.scrollTo(0, 0);

    const lenis = new Lenis();
    function raf(time) {
      lenis.raf(time);
      ScrollTrigger.update()
      requestAnimationFrame(raf);
    }
    requestAnimationFrame(raf);



    const body = document.querySelector('body');
    // const headerLogo = document.querySelector('.header-logo');
    const mask = document.querySelector('.barba-mask');
    const maskTop = document.querySelector('.barba-mask-top');
    const maskBottom = document.querySelector('.barba-mask-bottom');

    barba.init({
      timeout: 5000,

      // debug: true,
      // cacheIgnore: true,
      // prefetchIgnore: true,

      // requestError: (trigger, action, url, response) => {
      //   console.log({
      //     trigger: trigger,
      //     action: action,
      //     url: url,
      //     response: response,
      //   })
      // },

      prevent: ({ el }) => el.classList && (el.classList.contains('prevent-barba') || el.classList.contains('active')),

      transitions: [
        {
          name: 'default-transition',

          once(data) {
            setTimeout(() => {
              new Header();
              new NavMobile();
              new IdAccordion('.js--accordion');
              new Carousel('.js--carousel');
              new Reveal('.js--reveal');
              new Video('.js--video');
              new Hero();
            }, 1);

            let tl = gsap.timeline({
              delay: 1.5,
            })

            tl.to(mask, {
              duration: 1,
              opacity: 0,
              onStart: () => {
                new Text('.js--text');
              },
              onComplete: () => {
                mask.classList.remove('visible');
                mask.classList.remove('bg-theme-sand');
                mask.classList.add('invisible');
                mask.style.removeProperty('opacity');
              }
            });

            if (document.querySelector('.hero-logo-inner')) {
              tl.from('.hero-logo-inner path', {
                duration: 0.5,
                y: '50%',
                rotateY: 60,
                autoAlpha: 0,
                stagger: -0.05,
                // delay: 0.3,
              })
            }

            return tl;
          },

          beforeLeave(data) {
            maskTop.classList.remove('origin-top');
            maskTop.classList.add('origin-bottom');
            maskBottom.classList.remove('origin-bottom');
            maskBottom.classList.add('origin-top');
            // maskBottom.classList.toggle('origin-bottom', 'origin-top');

            let tl = gsap.timeline({
              delay: 0.1,
            });
            tl.to(maskTop, {
              duration: 0.3,
              scaleY: 1,
              onStart: () => {
                mask.classList.replace('invisible', 'visible');
              },
              // onComplete: () => {
              //   console.log('scroll 2');
              //   lenis.scrollTo(0, { immediate: true, force: true });
              // },
            });
            tl.to(maskBottom, {
              duration: 0.3,
              scaleY: 1,
            }, '<');

            return tl;
          },

          afterLeave(data) {
            // update body classes
            body.classList.toggle('is-home', data.next.namespace === 'home');
            body.classList.toggle('not-home', data.next.namespace !== 'home');
          },

          beforeEnter(data) {
            data.current.container.remove();
          },

          enter(data) {
            // reinit JS
            setTimeout(() => {
              new Header();
              new NavMobile();
              new IdAccordion('.js--accordion');
              new Carousel('.js--carousel');
              new Reveal('.js--reveal');
              new Video('.js--video');
              new Hero();
            }, 1);

          },

          afterEnter(data) {
            maskTop.classList.remove('origin-bottom');
            maskTop.classList.add('origin-top');
            maskBottom.classList.remove('origin-top');
            maskBottom.classList.add('origin-bottom');

            lenis.scrollTo(1, { immediate: true, force: true });

            let tl = gsap.timeline({
              delay: 0.5,
            })
            tl.to(maskTop, {
              duration: 0.5,
              scaleY: 0,
              onStart: () => {
                                new Text('.js--text');
              },
              onComplete: () => {
                mask.classList.replace('visible', 'invisible');
              },
            });
            tl.to(maskBottom, {
              duration: 0.5,
              scaleY: 0,
            }, '<');

            return tl;
          }

        },
      ],

    });
  }

}
