import once from "@drupal/once";
import { gsap, SplitText, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(SplitText, ScrollTrigger);

export default class Text {

  constructor(selector) {
    const elements = once('text-splitted', selector);
    elements.forEach(element => {
      let split = new SplitText(element, {
        type: 'lines,words',
      });

      // gsap.set(element, { perspective: 400 });
      gsap.from(split.words, {
        duration: 0.5,
        y: '50%',
        rotateX: 60,
        autoAlpha: 0,
        stagger: 0.025,
        delay: 0.3,
        scrollTrigger: {
          trigger: element,
          start: "top bottom",
          // markers: true,
          once: true,
        }
      });
    });
  }

}
