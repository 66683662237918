import Swiper from 'swiper';
import { EffectFade, Autoplay } from "swiper/modules"
import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

import "swiper/css"
import "swiper/css/autoplay"
import "swiper/css/effect-fade"

export default class Hero {

  constructor() {
    this.container = document.querySelector('.js--hero');
    if (!this.container) { return }

    this.html = document.querySelector('html');
    this.wrapper = document.querySelector('.hero');
    this.logo = this.wrapper.querySelector('.hero-logo');
    this.logoInner = this.wrapper.querySelector('.hero-logo-inner');
    this.images = this.wrapper.querySelector('.hero-images');
    this.header = document.querySelector('.header');
    this.headerLogo = document.querySelector('.header-logo');

    window.addEventListener('resize', (event) => {
      this.resizeHandler();
    });
    this.resizeHandler();

    this.init();
  }


  resizeHandler() {
    this.html.style.setProperty('--hero-logo-height', this.logo.getBoundingClientRect().height + 'px');
    ScrollTrigger.refresh();
  }


  init() {
    // gallery
    const swiper = new Swiper(this.wrapper.querySelector('.swiper-carousel'), {
      modules: [EffectFade, Autoplay],
      autoplay:{
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
      effect: "fade",
      speed: 2000,
      loop : true,
      keyboard : {
        enabled: false,
        // onlyInViewport: true,
      }
    });

    // logo pin
    ScrollTrigger.create({
      trigger: this.container,
      pin: this.logo,
      pinSpacing: false,
      start: () => `top ${this.header.clientHeight}`,
      end: () => `+=${(this.images.getBoundingClientRect().bottom - this.header.clientHeight)}`,
      scrub: true,
      // markers: true,
    });

    // header logo reveal
    gsap.fromTo(this.headerLogo, { y: '100%' }, {
      y: '0',
      scrollTrigger: {
        trigger: this.logo,
        start: () => `top ${(this.header.clientHeight)}`,
        end: () => `bottom ${this.header.clientHeight}`,
        scrub: true,
        // markers: true,
      },
    });
  }

}
